
import { Vue, Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import Header from '@/components/Header/Header.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import AssignmentSection from '@/components/Assignment/AssignmentSection.vue';
import Cover from '@/components/shared/Cover.vue';
import Footer from '@/components/Footer/Footer.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { DeliveryService } from '@/services';
import { CoverDataList, RouteName } from '@/models';

@Component({
  name: 'AssignmentListByDiscipline',
  components: {
    Header,
    ButtonDefault,
    AssignmentSection,
    Cover,
    Footer,
    Spinner,
  },
})
export default class AssignmentListByDiscipline extends Vue {
  private nextPage = 1;
  public covers: CoverDataList = new CoverDataList();
  public requesting = false;
  public lastPage = false;

  created() {
    window.addEventListener('scroll', this.checkScroll);

    this.getAssignmentList();
  }

  destroyed() {
    window.removeEventListener('scroll', this.checkScroll);
  }

  public goToPublishedAssignment(id: string) {
    this.$router.push({
      name: RouteName.PUBLISHED_ASSIGNMENT,
      params: { entregaId: id },
    });
  }

  private getAssignmentList() {
    const disciplineId = Number(this.$router.currentRoute.params.disciplinaId);
    this.requesting = true;

    DeliveryService.getDeliveryByDiscipline(disciplineId, this.nextPage)
      .then((resp: CoverDataList) => {
        if (this.nextPage === 1) {
          this.covers = resp;
          this.nextPage++;
          return;
        }

        this.covers.deliveries = [
          ...this.covers.deliveries,
          ...resp.deliveries,
        ];

        if (resp.deliveries.length) this.nextPage++;

        this.lastPage = resp.deliveries.length < 20;
      })
      .catch((err: AxiosError) => console.log(err))
      .finally(() => this.requesting = false);
  }

  private checkScroll() {
    const { offsetHeight, scrollTop } = document.documentElement;
    const { innerHeight } = window;

    const currentPosition = Math.ceil(scrollTop + innerHeight);

    if (this.lastPage || currentPosition < offsetHeight || this.requesting) return;

    this.getAssignmentList();
  }
}
