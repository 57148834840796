
import { Component, Prop, Vue } from 'vue-property-decorator';

import Avatar from '@/components/shared/Avatar.vue';

import { CoverData } from '@/models';

@Component({
  name: 'Cover',
  components: {
    Avatar,
  },
})
export default class Cover extends Vue {
  @Prop({}) data!: CoverData;
}
