
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Footer',
})
export default class Footer extends Vue {
  @Prop({ default: false }) lightMode: boolean;

  get email(): string {
    return `${this.lightMode ? 'atendimento' : 'aluno'}@fiaonline.com.br`;
  }
}
